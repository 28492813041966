const earn = {
  main: {
    header: {
      earning: "Generar",
      opportunity: "ingresos"
    },
    subheader: "¡Gane dinero a cambio de compartir su valiosa experiencia con nosotros!<sup>1</sup>",
    cta: "Comenzar a ganar",
  },
  subheader: "Earn cash rewards by sharing your valuable experiences with us!",
  cta: "Start earning",
  services: {
    header: "Invertimos en usted",
    items: [
      {
        title: "Gane dinero en línea",
        content: "Gane dinero al compartir su valiosa experiencia con nosotros al formar parte del Grupo de Opinión. ¡Los temas de las encuestas son muy variados!"
      },
      {
        title: "Alce la voz",
        content: "Reciba pagos por responder encuestas y ayudar a las empresas a desarrollar productos aptos para la comunidad. ¡Comparta su opinión hoy mismo!"
      },
      {
        title: "Reciba pagos",
        content: "Acumule $10 para obtener una tarjeta prepagada Rewards Card.<sup>2</sup>"
      },
      {
        title: "Con usted en cada paso",
        content: "¡Estamos aquí para ayudarle! Encuentre apoyo personalizado y confiable para guiarle a través de la experiencia del programa de Rewards."
      }
    ],
    cta: "Comenzar a ganar"
  },
  chat: {
    header: "¿Tiene alguna pregunta?",
    content: "¡Nuestro Equipo de Atención al Cliente está feliz de ayudarle! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
    cta: "Iniciar conversación"
  },
  rewards: {
    header: "Recompensamos su participación",
    subheader: "Hacemos que ganar dinero en línea sea fácil",
    items: [
      {
        title: "Oportunidades de generar ingresos",
        content: "El programa de Rewards<sup>1</sup> le ofrece encuestas de diferentes duraciones y se le compensa acorde a ello. ¡Es así de simple!"
      },
      {
        title: "Empoderamiento financiero",
        content: "Nuestro objetivo es empoderar a todas las personas, sin importar su género, etnicidad o ingresos."
      },
      {
        title: "Múltiples oportunidades de ganar",
        content: "¡Participar activamente le brinda más oportunidades de ganar!"
      },
      {
        title: "Evolucionamos para usted",
        content: "Acceda a ofertas creadas especialmente para la comunidad."
      }
    ]
  },
  testimonials: {
    header: "Voces de la comunidad",
    items: [
      {
        content: "“Recomiendo las encuestas para obtener dinero rápido y fácil. ¿Y sabes cuál es la mejor parte? ¡Que las respondo <strong>directamente desde mi celular!</strong>”",
        span: "- Monica A. Dallas, TX"
      },
      {
        content: "“¡Me encantan las encuestas en la aplicación PODERcard! <strong>Es tan fácil obtener recompensas y agregar dinero</strong> todos los días. Excelente servicio.“",
        span: "- Diana A. Denver, CO"
      },
      {
        content: "“Muy buenos incentivos de recompensa con <strong>pagos rápidos.</strong>“",
        span: "- Diana A. Denver, CO"
      }
    ]
  },
  articles: {
    header: "Tips para generar ingresos",
    cta: "Lea más"
  },
  faq: {
    header: "Preguntas frecuentes",
    items: [
      {
        title: "¿Alrededor de cuánto dinero se gana?",
        content: "Puede ganar entre $0.50 y $5.00 en promedio por cada encuesta completada. El monto del pago depende del aliado y de qué tan larga es la encuesta.",
      },
      {
        title: "¿Por qué pagan por responder encuestas?",
        content: "La comunidad sigue creciendo y su influencia también. Por eso hay muchas marcas que quieran entender mejor las necesidades y retos de nuestra comunidad. Su opinión vale mucho, y debería de ser remunerada por ello. Es así de simple.",
      },
      {
        title: "¿Sobre qué temas puedo aprender?",
        content: "Ofrecemos una variedad de temas para aprender, sin embargo resaltamos las áreas que sabemos impactan más a la comunidad: servicios financieros y médicos. Todas las familias necesitan contar con suficientes ingresos para cuidar de su familia, para mantenerla sana y segura.",
      }
    ],
    more: {
      title: "¿Tiene alguna pregunta?",
      content: "¡Nuestro Equipo de Atención al Cliente está feliz de ayudarle! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
      cta: "Iniciar conversación"
    }
  },
  disclaimer: {
    title: "Divulgaciones",
    items: [
      "Consulte nuestros <a href='/legal/terms-and-conditions' target='_blank' rel='noopener noreferrer'>Términos y Condiciones</a> para obtener detalles completos del programa.",
      "Consulte el <a href='https://dd8u8m75fccea.cloudfront.net/v3/assets/bltd488044897c9abc0/blt4fa10291f5a1f8c6/632cac652343d638c69429e3/SEP_Rewards_Prepaid_Card_ES.docx.pdf' target='_blank' rel='noopener noreferrer'>Acuerdo de Tarjetahabientes Visa Prepaid Corporate Reloadable Rewards Card</a> para mayor información."
    ]
  }
};

export default earn;
