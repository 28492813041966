const home = {
  main: {
    header: {
      welcome: "Welcome to",
      text: ["Opportunity", "Service", "Trust", "Potential"],
    },
    subheader: "Best in class services built for you and your family",
    cta: "Join today!"
  },
  services: {
    header: "The <strong>SABEResPODER</strong> Difference",
    items: [
      {
        title: "Health and Wellness",
        content: "Hassle-free and affordable medical services<sup>1</sup> for you and your family."
      },
      {
        title: "Earning Opportunities",
        content: "Earn cash by participating in unique surveys, rewards, and other income opportunities."
      },
      {
        title: "Banking Services",
        content: "Open a bank account quickly and easily, no social security number required.<sup>2</sup>"
      },
      {
        title: "Trusted Advisor",
        content: "Navigate healthcare<sup>1</sup>, education & other services while living in the U.S."
      },
    ],
    popular_products: 'POPULAR PRODUCT!',
  },
  benefits: {
    header: "Benefits and Services",
    health: {
      title: "Health and Wellness",
      content: "Hassle-free and affordable medical services<sup>1</sup> for you and your family.",
      items: ["Dental", "Medical Care", "Vision", "Pharmacy"],
      cta: "Learn more"
    },
    rewards: {
      title: "Earning Opportunities",
      content: "Earn cash by participating in unique surveys, rewards, and other income opportunities.",
      items: ["Paid Surveys", "Cash Earnings", "Member Benefits", "Job Tips"],
      cta: "Learn more"
    },
    banking: {
      title: "Banking Services",
      content: "Open a bank account quickly and easily, no social security number required.<sup>2</sup>",
      items: ["Direct Deposit<sup>3</sup>", "Digital Transactions", "Cash Earnings<sup>4</sup>", "Instant Virtual Card"],
      cta: "Learn more"
    },
    advisor: {
      title: "Trusted Advisor",
      content: "Navigate healthcare<sup>1</sup>, education & other services while living in the U.S.",
      items: ["Confidential Advisor", "Community Surveys", "Educational Content", "Community Workshops <span>(coming soon)</span>"],
      cta: "Learn More"
    }
  },
  articles: {
    header: "Knowledge Hub",
    cta: "Read Article"
  },
  testimonials: {
    header: "Community Voices",
    items: [
      {
        content: "“Reliable and very good reward incentives with <strong>fast payments.</strong>“",
        span: "- Diana A. Denver, CO"
      },
      {
        content: "“Reliable and very good reward incentives with <strong>fast payments.</strong>“",
        span: "- Diana A. Denver, CO"
      }
    ]
  },
  faq: {
    header: "Frequently Asked Questions",
    items: [
      {
        title: "What is SABEResPODER?",
        content: "SABEResPODER serves as a trusted advisor for a community of over 4 million individuals, empowering them with the knowledge and tools required to make informed choices, enhance their quality of life and attain greater financial and social stability.",
      },
      {
        title: "How can SABEResPODER help me?",
        content: "We are here to promote improved wellbeing with best-in-class health resources, increased financial strength, income generation through online earning opportunities, and trusted information to achieve a more fulfilling life in the United States.",
      },
      {
        title: "Is SABEResPODER free?",
        content: "It is free to join our community, receive trusted information, and start earning. We provide other best-in-class services at affordable rates, promoting physical and financial wellbeing.",
      },
      {
        title: "Is SABEResPODER available outside of the US?",
        content: "Our trusted content is available outside of the United States, but services like our healthcare savings are available only in the U.S. territory. Contact an agent to get a full breakdown of where our services are offered and whether they are right for you.",
      }
    ],
    more: {
      title: "Do you have any questions?",
      content: "Our Customer Service Team is happy to assist you! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
      cta: "Chat with an expert"
    }
  },
  disclaimer: {
    title: "Disclaimer",
    items: [
      "Discount Plan Application. This program is NOT insurance coverage and does not meet the minimum creditable coverage requirements under the Affordable Care Act or Massachusetts M.G.L. c. 111M and 956 CMR 5.00. Your membership is effective upon receipt of membership materials. This program is not available to UT, VT and WA residents. It contains a 30 day cancellation period, provides discounts only at the offices of contracted health care providers, and each member is obligated to pay the discounted medical charges in full at the point of service. The range of discounts for medical or ancillary services provided under the program will vary depending on the type of provider and medical or ancillary service received. Member shall receive a reimbursement of all periodic membership fees if membership is cancelled within the first 30 days after the effective date. Discount Plan Organization: New Benefits, Ltd., Attn: Compliance Department, PO Box 803475, Dallas, TX 75380-3475 Form #HPSEP2",
      "Welcome Tech dba SABEResPODER is not a bank. Banking services provided by Thread Bank, Member FDIC. The PODERcard Visa Debit Card is issued by Thread Bank, Member FDIC, pursuant to a license from Visa U.S.A. Inc and may be used everywhere Visa debit cards are accepted. PODERcard account opening is subject to identity verification by Thread Bank.<br/><br/>Your deposits qualify for up to $3,000,000* in FDIC insurance coverage when placed at program banks in the Thread Bank deposit sweep program. Your deposits at each program bank become eligible for FDIC insurance up to $250,000, inclusive of any other deposits you may already hold at the bank in the same ownership capacity. You can access the terms and conditions of the sweep program at <a href='https://go.thread.bank/sweepdisclosure' target='_blank' rel='noopener noreferrer'>https://go.thread.bank/sweepdisclosure</a> and a list of program banks at <a href='https://go.thread.bank/programbanks' target='_blank' rel='noopener noreferrer'>https://go.thread.bank/programbanks</a>. Please contact <a href='mailto:customerservice@thread.bank' target='_blank' rel='noopener noreferrer'>customerservice@thread.bank</a> with questions regarding the sweep program. *See more here: <a href='https://go.thread.bank/sweepdisclosure' target='_blank' rel='noopener noreferrer'>https://go.thread.bank/sweepdisclosure</a>.",
      "See <a href='https://dd8u8m75fccea.cloudfront.net/v3/assets/bltd488044897c9abc0/blte14859d0ceca95c5/657776d641160e89c93571ec/Welcome_Tech_Thread_Consumer_Deposit_Agreement_2023_11_21_en.pdf' target='_blank' rel='noopener noreferrer'>Deposit Account Agreement</a> for fees and additional details in our <a href='/en/legal/terms-and-conditions' target='_blank' rel='noopener noreferrer'>terms and conditions and privacy policy.</a>",
      "Other SABEResPODER products, services, and discounts are not provided by Thread Bank."
    ],
    links: {
      thread_bank_cardholder_account_agreement: 'Thread Bank Cardholder Account Agreement',
      thread_bank_deposit_account_agreement: 'Thread Bank Deposit Account Agreement',
      electronic_communications_agreement: 'Electronic Communications Agreement',
      thread_bank_privacy_policy: 'Thread Bank Privacy Policy',
      welcome_tech_privacy_policy: 'Welcome Tech Privacy Policy',
    },
  },
  page404: {
    subheader: "Page not found",
    cta: "Return to home"
  }
};

export default home;
