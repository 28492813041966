const home = {
  main: {
    header: {
      welcome: "SABER es",
      text: ["Oportunidad", "Servicio", "Confianza", "Poder"],
    },
    subheader: "Servicios a la medida para usted y su familia",
    cta: "¡Únase hoy!"
  },
  services: {
    header: "¿Por qué <strong>SABEResPODER?</strong>",
    items: [
      {
        title: "Salud y bienestar",
        content: "Servicios médicos accesibles<sup>1</sup>, para usted y su familia inmediata."
      },
      {
        title: "Generar ingresos",
        content: "¡Gane dinero a cambio de compartir su valiosa experiencia con nosotros!"
      },
      {
        title: "Servicios bancarios",
        content: "Abra una cuenta bancaria fácil y rápido, sin requerir de un número de seguro social.<sup>2</sup>"
      },
      {
        title: "Asesoría confiable",
        content: "Le ayudamos a utilizar los servicios de salud<sup>1</sup>, de educación y otros en EE.UU."
      },
    ],
    popular_products: '¡Alta demanda!',
  },
  benefits: {
    header: "Servicios y beneficios",
    health: {
      title: "Salud y bienestar",
      content: "Servicios médicos accesibles<sup>1</sup>, para usted y su familia inmediata.",
      items: ["Dentistas", "Atención médica", "Cuidado de la vista", "Medicamentos descontados"],
      cta: "Conozca más"
    },
    rewards: {
      title: "Generar ingresos",
      content: "Gane dinero al participar en encuestas pagadas, recompensas ¡y otras oportunidades de ingresos<sup>3</sup>!",
      items: ["Encuestas pagadas", "Ganancias en efectivo", "Beneficios para la comunidad", "Tips para encontrar trabajo"],
      cta: "Conozca más"
    },
    banking: {
      title: "Servicios bancarios",
      content: "Abra una cuenta bancaria fácil y rápido, sin requerir de un número de seguro social.<sup>2</sup>",
      items: ["Depósito directo<sup>3</sup>", "Compras en línea", "Ganancias en efectivo<sup>4</sup>", "Tarjeta virtual inmediata"],
      cta: "Conozca más"
    },
    advisor: {
      title: "Asesoría confiable",
      content: "Nuestro equipo de expertos le ayudará a utilizar los servicios de salud<sup>1</sup>, de educación y otros en EE.UU.",
      items: ["Asesoría confidencial", "Encuestas comunitarias", "Contenido educativo", "Eventos y talleres <span>(Próximamente)</span>"],
      cta: "Conozca más"
    },
  },
  articles: {
    header: "Centro de conocimiento",
    cta: "Lea más"
  },
  testimonials: {
    header: "Voces de la comunidad",
    items: [
      {
        content: "“Muy buenos incentivos de recompensa con <strong>pagos rápidos.</strong>“",
        span: "- Diana A. Denver, CO"
      },
      {
        content: "“Muy buenos incentivos de recompensa con <strong>pagos rápidos.</strong>“",
        span: "- Diana A. Denver, CO"
      }
    ]
  },
  faq: {
    header: "Preguntas frecuentes",
    items: [
      {
        title: "¿Qué es SABEResPODER?",
        content: "SABEResPODER ofrece asesoría confiable para una comunidad de más de 4 millones de personas, empoderándolas con el conocimiento y las herramientas necesarias para tomar decisiones informadas, mejorar su calidad de vida y alcanzar una mayor estabilidad financiera y social.",
      },
      {
        title: "¿Los servicios de SABEResPODER son gratuitos?",
        content: "Unirse a nuestra comunidad es gratis, así como también consultar información confiable y ganar dinero con el programa de Rewards. Además, ofrecemos otros servicios de primera para promover el bienestar físico y financiero.",
      },
      {
        title: "¿Cómo puede SABEResPODER ayudarme?",
        content: "Estamos aquí para promover un mejor bienestar mediante recursos de salud, servicios bancarios accesibles, opciones para generar ingresos en línea y para brindar información confiable para lograr una vida más plena en los Estados Unidos.",
      },
      {
        title: "¿Se puede acceder a SABEResPODER afuera de los Estados Unidos?",
        content: "Nuestros contenidos están disponibles fuera de EE.UU., pero servicios como PODERsalud están disponibles solamente en territorio estadounidense. Contacte al Equipo de Atención al Cliente para obtener un desglose completo de nuestros servicios y determinar si son adecuados para usted.",
      }
    ],
    more: {
      title: "¿Tiene alguna pregunta?",
      content: "¡Nuestro Equipo de Atención al Cliente está feliz de ayudarle! <a href='tel:(726) 202-1213'>(726) 202 1213</a>",
      cta: "Iniciar conversación"
    },
  },
  disclaimer: {
    title: "Divulgaciones",
    items: [
      "Inscripción para plan de descuento. Este programa NO ES una póliza de seguros, la intención NO ES reemplazar a su compañía de seguros, y tampoco cumple con los requisitos mínimos de cobertura que piden el Affordable Care Act o el M.G.L. c. 111M y 956 CRM 5.00 de Massachusetts. Su membresía es efectiva desde la recepción de los materiales de membresía. Este programa no está disponible para residentes de UT, VT o WA. Tras inscribirse, Tendrá 30 días para evaluar el servicio y si no queda satisfecho en ese período, puede cancelar el programa sin costo alguno y le devolveremos su dinero. Los descuentos los puede utilizar sólo en las oficinas de los proveedores de servicios médicos que forman parte de la red, y cada titular de la membresía tiene la obligación de pagar los servicios descontados en el momento en el que se reciben. Discount Plan Organization: New Benefits, Ltd., Attn: Compliance Department, PO Box 803475, Dallas, TX 75380-3475. Formulario # HPSEP2",
      "Welcome Tech, que opera como SABEResPODER, no es un banco. Los servicios bancarios son proporcionados por Thread Bank, miembro de la Federal Deposit Insurance Corporation (FDIC). La tarjeta PODERcard Visa Debit Card es expedida por Thread Bank, Miembro FDIC, de acuerdo con una licencia de Visa U.S.A. Inc., y puede utilizarse en todos los lugares donde se aceptan tarjetas Visa. La apertura de la cuenta PODERcard está sujeta a una verificación de identidad por Thread Bank.<br/><br/>Sus depósitos son elegibles para ser asegurados por la FDIC hasta $3,000,000* al resguardarse en bancos que forman parte del programa de transferencia automática de depósitos de Thread Bank. Sus depósitos en cada banco que forma parte del programa son elegibles para el seguro de la FDIC hasta $250,000, incluyendo cualquier otro depósito que ya tenga en el banco al cual usted tenga el mismo derecho. Puede acceder los términos y condiciones del programa de transferencia automática de depósitos en <a href='https://go.thread.bank/sweepdisclosure' target='_blank' rel='noopener noreferrer'>https://go.thread.bank/sweepdisclosure</a> (contenido disponible solo en inglés) y el listado de bancos que forman parte del programa en <a href='https://go.thread.bank/programbanks' target='_blank' rel='noopener noreferrer'>https://go.thread.bank/programbanks</a>. Si tiene preguntas sobre el programa, por favor escriba a <a href='mailto:customerservice@thread.bank' target='_blank' rel='noopener noreferrer'>customerservice@thread.bank</a>. *Vea más aquí: <a href='https://go.thread.bank/sweepdisclosure' target='_blank' rel='noopener noreferrer'>https://go.thread.bank/sweepdisclosure</a> (contenido disponible solo en inglés).",
      "Consulte el <a href='https://dd8u8m75fccea.cloudfront.net/v3/assets/bltd488044897c9abc0/blt120185552d60d489/657776d6463ba55bb7bad0be/Welcome_Tech_Thread_Consumer_Deposit_Agreement_2023_11_21_es.pdf' target='_blank' rel='noopener noreferrer'>Acuerdo de Cuenta de Depósito</a> para obtener detalles adicionales en nuestros <a href='/legal/terms-and-conditions' target='_blank' rel='noopener noreferrer'>términos y condiciones.</a>",
      "Otros productos, servicios o descuentos de SABEResPODER no son proveídos por Thread Bank."
    ],
    links: {
      thread_bank_cardholder_account_agreement: 'Acuerdo del Titular de la Tarjeta de Crédito de Thread Bank',
      thread_bank_deposit_account_agreement: 'Acuerdo de Cuenta de Depósito de Thread Bank',
      electronic_communications_agreement: 'Contrato de Comunicaciones Electrónicas',
      thread_bank_privacy_policy: 'Política de Privacidad de Thread Bank',
      welcome_tech_privacy_policy: 'Política de Privacidad de Welcome Tech',
    },
  },
  page404: {
    subheader: "Página no encontrada",
    cta: "Volver a la inicio"
  }
};

export default home;
