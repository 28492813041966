const benefits = {
  index: {
    benefits_title: 'Wellness Hub',
    description: 'Explore medical discounts and access consultations with ease. Better healthcare is only a call or a click away!',
    featured_benefit: 'Featured',
    popular_benefit: 'Popular benefits',
    other_benefit: 'Other benefits',
    silver_benefit: 'Benefits',
    need_help: 'Got a question?',
    details: 'Details',
    call: 'Call',
    start_chat_now: 'Start chat',
    call_our_support: 'Call Us',
    start_live_chat: "Our customer support agents are more than happy to assist you!",
    information: 'About this benefit',
    hide_benefits: 'Show less',
    show_benefits: 'Show more',
    book_appointment: 'Ready to start saving?',
    call_service: 'Call Now',
    faq: 'FAQ',
    faqs: '<p>FAQ</p>',
    which_one_do_you_want_to_learn: 'Which one do you want to learn more about?',
    learn_more: 'Learn more',
    benefit_card: 'benefit card',
    view_card: 'View Card',
    your_membership_card: 'Your membership card',
    discount_card: 'Discount card',
    to_receive_your_discount: 'To receive your discount show your card at the reception desk.',
    this_is_not_insurance: 'THIS IS NOT INSURANCE',
    call_customer_care: 'Call Customer Care',
    membership_id: 'Membership ID:',
    group_id: 'Group ID:',
    membership_id_information: 'Use your membership ID when speaking to customer support',
    group_id_information: 'Use your group ID when speaking to customer support',
    membership_id_is_not_available: 'Still loading… may take up to 15 minutes',
    podersalud: 'PODERsalud',
    my_benefits: 'Wellness Hub',
    header_subtitle: 'SEP+',
  },
  find_providers: {
    view_map: 'View map',
    view_list: 'View list',
    call: 'Call',
    loading: 'Loading...',
    providers: 'Providers',
    add_favorite_providers: 'Add favorite providers',
    tap_the_heart_to_add: 'Tap the heart to add',
    got_it: 'Got it',
    see_your_favorite_providers: 'See your favorite providers',
    favorite_providers: 'Favorite providers',
    provider_name_removed_from_favorites: 'This provider has been removed from your favorites',
    my_favorites: 'My favorites',
    no_providers_found: 'No providers found',
    try_another_zipcode: 'No providers were found in the area. Please try a different zip code.',
    try_again: 'Try again',
    specialty: 'Choose Specialty',
  },
  dental: {
    title: 'Dental',
    description: 'Big savings on dental services at thousands of locations nationwide.',
    descriptionDesktop: 'Big savings on dental services at thousands of locations nationwide.',
    save_on: 'Big savings on dental services at thousands of locations nationwide',
    information: 'Prioritize your oral health with savings powered by Aetna Dental Access® at 262,000<sup>1</sup> dental locations nationwide.',
    htmlContent: '<ul><li>Unlock 15-50%<sup>2</sup> discounts on cleanings, X-rays, crowns and more —including specialty care.</li><li>Access quality care while enjoying significant cost savings<sup>2</sup>.</li><li>Actual savings vary by provider and location.</li></ul>',
    how_to_use: 'How to use:',
    how_to_use_steps: '<ol><li>Locate providers near you by selecting the “Find a dentist” option below.</li><li>Utilize the map to identify dental providers in your area.</li><li>Select a dental provider.</li><li>Call the dental provider and confirm they accept the <strong>Aetna Dental Access PPO Fee Schedule</strong>. It is important to do this <strong>before</strong> arriving at the dentist.</li><li>All dental providers are expected to accept the <strong>Aetna Dental Access PPO Fee Schedule</strong>. If you encounter any issues, please contact customer support immediately by calling {{ phone }}.</li><li>Book your appointment.</li><li>When you arrive at the dentist, confirm the price ahead of procedure/consultation and present the digital or physical membership card.</li><li>Should you experience any difficulties during your dental visit, kindly reach out to our customer support promptly at {{ phone }}.</li></ol>',
    important: '<strong>Important:</strong> If the provider is not familiar with or accepts the Aetna Dental Access PPO Fee Schedule, please reach out to our customer support at {{ phone }} immediately.  Our dedicated team is ready to assist you in promptly resolving any concerns you may encounter.',
    disclaimer: '<p><sup>1</sup>According to the Aetna Enterprise Database as of October 2019.</p><p><sup>2</sup>Actual costs and savings vary by provider, service and geographical area.</p>',
    membershipCard: {
      title: 'Dental benefit card',
      subtitle: 'To receive your discount show your card at the reception desk.',
    },
    find_a_dentist: 'Find a dentist',
    providers_found_for_your_location: 'providers found for your location',
    save_your_favorite_providers: 'Save your favorite dentists',
    faq: {
      items: [
        {
          title: 'What if my dentist is not a participating provider?',
          htmlContent: "Recommend a Provider through the app, online or by calling Member Support. Submit the provider's information, and Aetna will contact the provider about joining the network.",
        },
        {
          title: 'Can I receive a discount at any dental office?',
          htmlContent: 'You must go to a participating provider in order to receive your discount. Find participating providers in the app or online, or call Member Support Monday to Friday, 7:00 am to 7:00 pm and Saturday, 8:00 am to 5:00 pm Central Time for questions or help locating a provider.',
        },
        {
          title: 'How many times can I use the Dental benefit?',
          htmlContent: 'There is no limit to the amount of times this benefit can be used.',
        },
      ],
    },
  },
  telemedicine: {
    title: 'Telemedicine',
    description: 'Doctors are a call or click away 24/7.',
    descriptionDesktop: 'Doctors are a call or click away 24/7.',
    save_on: 'Doctors are a call or click away 24/7',
    information: "24/7 access to a doctor anytime, anywhere with no per visit fee. Talk to a doctor to get a diagnosis, treatment options and prescription, if medically necessary. ",
    htmlContent: '<ul><li>Treatment for common medical issues.</li><li>10 minute average doctor response time.</li><li>U.S. board-certified doctors with an average 20 years practice experience.</li></ul>',
    how_to_use: 'How to use:',
    how_to_use_steps: '<ol><li>Wait 24 hours after enrollment before trying to book your first appointment.</li><li>Register by clicking the “Request consult online” button below.</li><li>You will be signed in to your Teladoc account (this may take up to 1 minute).</li><li>Follow the prompts to register your Teladoc account.</li><li>After registering your Teladoc account, you will need to fill out your medical history.</li><li>Add a pharmacy to your account that will be used for filling any prescriptions.</li><li>To schedule a consultation, click the “Talk to a Doctor” button and follow the prompts.</li><li>Should you experience any difficulties, kindly reach out to customer support at {{ phone }}.</li></ol>',
    important: '<strong>Important:</strong> If you experience any difficulties, kindly reach out to customer support at {{ phone }} immediately. Our dedicated team is ready to assist you in promptly resolving any concerns you may encounter.',
    faq: {
      items: [
        {
          title: 'Do I speak with real doctors?',
          htmlContent: 'Yes. You will speak with doctors who are U.S. board-certified internists, state-licensed family practitioners, and pediatricians licensed to practice medicine in the U.S. and living in the U.S. When you request a visit, Teladoc will connect you with a doctor licensed in your state.',
        },
        {
          title: 'Can I call Teladoc outside of the United States?',
          htmlContent: 'No. Teladoc visits are unavailable outside of the United States.',
        },
        {
          title: 'Is my electronic health record kept private?',
          htmlContent: 'Teladoc employs robust encryption methods to protect your personal information. You determine who can see the information in your record.',
        },
        {
          title: 'How are prescriptions sent to the pharmacy?',
          htmlContent: 'Teladoc does not dispense prescription drugs. If the doctor prescribes medication, it is submitted electronically or by phone to your pharmacy.',
        },
        {
          title: 'What are some of the common conditions Teladoc treats?',
          htmlContent: 'Common conditions include sinus problems, respiratory infections, allergies, urinary tract infections, cold and flu symptoms and many other non-emergency illnesses.',
        },
        {
          title: 'Can Teladoc handle my emergency situations?',
          htmlContent: 'Teladoc is designed to handle non-emergent medical problems. You should not use it if you are experiencing a medical emergency.',
        },
        {
          title: 'Can I request a particular doctor?',
          htmlContent: 'No. Teladoc is designed to support your relationship with your existing doctor. It is not a means of establishing an exclusive relationship with a Teladoc doctor. Please know that all Teladoc doctors are highly qualified and go through rigorous training and credentialing.',
        },
        {
          title: 'Can I get a prescription?',
          htmlContent: 'Teladoc does not guarantee prescriptions. It is up to the doctor to recommend the best treatment. Teladoc doctors do not issue prescriptions for substances controlled by the DEA, non-therapeutic and/or certain other drugs which may be harmful because of their potential for abuse. These include, but are not limited to, antidepressant drugs such as Cymbalta, Prozac and Zoloft. Based on treatment protocols, doctors may not prescribe an antibiotic for viral illnesses such as most colds, sore throats, coughs, sinus infections and the flu. Doctors may suggest alternative treatment options such as a prescription for symptom relief or over-the-counter medication. Also, non-therapeutic drugs such as Viagra and Cialis are not prescribed by Teladoc.',
        },
      ],
    },
  },
  vision: {
    title: 'Vision',
    description: 'Save on glasses, contacts, laser surgery and exams.',
    descriptionDesktop: 'Save on glasses, contacts, laser surgery and exams.',
    save_on: 'Save on glasses, contacts, laser surgery and exams',
    benefits: [
      {
        eyewear: {
          title: 'Eyewear and Exams',
          description: 'Smile brighter with big savings at over 262,000* available dental practice locations nationwide',
          save_on: 'Save 10% to 60% on prescription eyewear and exams',
          information: 'Unlock significant savings on vision care for you and your family with Coast To Coast Vision™ at over 20,000<sup>1</sup> providers nationwide.',
          htmlContent: '<ul><li>Enjoy 20% to 60%<sup>2</sup> off prescription eyewear, including most frames, lenses, and specialty items such as tints, coatings, and UV protection.</li><li>Receive 10% to 30%<sup>2</sup> off eye exams.</li><li>Participating chains include LensCrafters®, Pearle Vision®, Visionworks, JCPenney Optical, Sears Optical, Target Optical®, and more.</li></ul>',
          how_to_use: 'How to use:',
          how_to_use_steps: "<ol><li>Locate providers near you by selecting the “Find a Provider” option below.</li><li>Select an Eyewear and Exam provider.</li><li>Call the Eyewear and Exam provider and confirm they accept the Coast To Coast Vision™ coverage. It is important to do this <strong>before</strong> arriving at the provider.</li><li>All Eyewear and Exam providers are expected to accept Coast To Coast Vision™. If you encounter any issues, please contact customer support immediately by calling {{ phone }}.</li><li>Book your appointment.</li><li>When you arrive at the provider's location, confirm the price ahead of procedure/consultation and present the digital or physical membership card.</li><li>Should you experience any difficulties during your visit, kindly reach out to our customer support promptly at {{ phone }}.</li></ol>",
          important: '<strong>Important:</strong> If the provider is not familiar with or accepts Coast To Coast Vision™, please reach out to our customer support at {{ phone }} immediately. Our dedicated team is ready to assist you in promptly resolving any concerns you may encounter.',
          disclaimer: '<p><sup>1</sup>As of October 2019.</p><p><sup>2</sup>Actual costs and savings vary by provider, service and geographical area.</p>',
          membershipCard: {
            title: 'Vision benefit card',
            subtitle: 'To receive your discount show your card at the reception desk.',
          },
          find_a_provider: 'Find a provider',
          providers_found_for_your_location: 'providers found for your location',
          save_your_favorite_providers: 'Save your favorite providers',
          faq: {
            items: [
              {
                title: 'Can I use this benefit if I already have vision insurance?',
                htmlContent: 'Depending on the type of insurance, the benefit may be utilized to reduce out of pocket expenses. For example, once the insurance benefit has been exhausted, you may use your discount to buy additional pairs of glasses or contacts.',
              },
              {
                title: 'Is there a limit on the number of times the benefit can be used?',
                htmlContent: 'There is no limit on the number of times you or your family can take advantage of the savings.',
              },
              {
                title: 'Can I use the benefit at any retail location?',
                htmlContent: 'No. To receive a discount, you must go to a provider in the Coast to Coast Vision network.',
              },
              {
                title: 'Is this benefit insurance?',
                htmlContent: 'No. This is a discount eyewear and eye care program. You will pay the discounted price at the time of the purchase. There are no reimbursements or paperwork to file.',
              },
              {
                title: 'What if the store is running a sale?',
                htmlContent: 'The discount cannot be combined with any other discounts or special offers.',
              },
              {
                title: 'What do I do when I get to the location to get my discount?',
                htmlContent: 'Be sure to show the participating provider your membership card with the Coast to Coast Vision logo at the time of purchase.',
              },
              {
                title: 'How do I get my eye doctor or optician in the Coast to Coast Vision network?',
                htmlContent: 'Recommend a Provider through the app, online or by calling Member Support. Submit the provider’s information, and Coast to Coast Vision will contact the provider about joining the network.',
              },
              {
                title: 'How can I be guaranteed the greatest savings on contact lenses?',
                htmlContent: 'The greatest savings and selection for contact lenses is offered through the mail order program. Replacement contact lenses are discounted at 10% to 40% below retail.',
              },
            ],
          },
        },
      },
      {
        order: {
          title: 'Mail Order Contacts',
          description: 'Smile brighter with big savings at over 262,000* available dental practice locations nationwide',
          save_on: 'Save 10% to 40% on mail order contact lenses',
          information: "Save 10% to 40% on contact lenses through America's Eyewear mail order service",
          htmlContent: '<ul><li>To order or get a quote, call Monday to Friday, 7:00 am to 7:00 pm and Saturday, 8:00 am to 5:00 pm Central Time</li><li>Email (americaseyewear@newbenefits.com) or fax (972-991-5218) a copy of your prescription.</li></ul>',
          externalLinkTitle: 'Email prescription',
          faq: {
            items: [
              {
                title: 'How can I be guaranteed the greatest savings on contact lenses?',
                htmlContent: 'The greatest savings and selection for contact lenses is offered through the mail order program. Replacement contact lenses are discounted at 10% to 40% below retail.',
              },
              {
                title: 'How do I get my eye doctor or optician in the Coast to Coast Vision network?',
                htmlContent: 'Recommend a Provider through the app, online or by calling Member Support. Submit the provider’s information, and Coast to Coast Vision will contact the provider about joining the network.',
              },
              {
                title: 'What if the store is running a sale?',
                htmlContent: 'The discount cannot be combined with any other discounts or special offers.',
              },
              {
                title: 'What do I do when I get to the location to get my discount?',
                htmlContent: 'Be sure to show the participating provider your membership card with the Coast to Coast Vision logo at the time of purchase.',
              },
              {
                title: 'Can I use the benefit at any retail location?',
                htmlContent: 'No. To receive a discount, you must go to a provider in the Coast to Coast Vision network.',
              },
              {
                title: 'Is there a limit on the number of times the benefit can be used?',
                htmlContent: 'There is no limit on the number of times you or your family can take advantage of the savings.',
              },
              {
                title: 'Can I use this benefit if I already have vision insurance?',
                htmlContent: 'Depending on the type of insurance, the benefit may be utilized to reduce out of pocket expenses. For example, once the insurance benefit has been exhausted, you may use your discount to buy additional pairs of glasses or contacts.',
              },
              {
                title: 'Is this benefit insurance?',
                htmlContent: 'No. This is a discount eyewear and eye care program. You will pay the discounted price at the time of the purchase. There are no reimbursements or paperwork to file.',
              },
            ],
          },
        },
      },
      {
        laser: {
          title: 'Laser Surgery',
          description: 'Smile brighter with big savings at over 262,000* available dental practice locations nationwide',
          save_on: '40% to 50% off the average cost of laser surgery',
          information: 'Save 40% to 50% on the national average cost of LASIK surgery',
          htmlContent: '<ul><li>Schedule an appointment with a participating LASIK surgery provider</li><li>At your appointment, present your membership card with the Coast To Coast Vision™ logo and pay the discounted total</li><li>Call Monday to Friday, 7:00 am to 7:00 pm, and Saturday, 8:00 am to 5:00 pm Central Time for help locating a provider</li></ul>',
          faq: {
            items: [
              {
                title: 'How can I be guaranteed the greatest savings on contact lenses?',
                htmlContent: 'The greatest savings and selection for contact lenses is offered through the mail order program. Replacement contact lenses are discounted at 10% to 40% below retail.',
              },
              {
                title: 'Is this benefit insurance?',
                htmlContent: 'No. This is a discount eyewear and eye care program. You will pay the discounted price at the time of the purchase. There are no reimbursements or paperwork to file.',
              },
              {
                title: 'Can I use this benefit if I already have vision insurance? ',
                htmlContent: 'Depending on the type of insurance, the benefit may be utilized to reduce out of pocket expenses. For example, once the insurance benefit has been exhausted, you may use your discount to buy additional pairs of glasses or contacts.',
              },
              {
                title: 'Is there a limit on the number of times the benefit can be used?',
                htmlContent: 'There is no limit on the number of times you or your family can take advantage of the savings.',
              },
              {
                title: 'Can I use the benefit at any retail location?',
                htmlContent: 'No. To receive a discount, you must go to a provider in the Coast to Coast Vision network.',
              },
              {
                title: 'What do I do when I get to the location to get my discount?',
                htmlContent: 'Be sure to show the participating provider your membership card with the Coast to Coast Vision logo at the time of purchase.',
              },
              {
                title: 'What if the store is running a sale?',
                htmlContent: 'The discount cannot be combined with any other discounts or special offers.',
              },
              {
                title: 'How do I get my eye doctor or optician in the Coast to Coast Vision network? ',
                htmlContent: 'Recommend a Provider through the app, online or by calling Member Support. Submit the provider’s information, and Coast to Coast Vision will contact the provider about joining the network.',
              },
            ],
          },
        },
      },
    ],
  },
  rx_valet: {
    title: 'RX Valet',
    description: 'Save 10% to 85% on most medications.',
    descriptionDesktop: 'Save 10% to 85% on most medications.',
    save_on: 'Save 10% to 85% on most medications',
    information: 'Save<sup>1</sup> on your prescriptions with deep discounts through NB Rx<sup>2</sup>.',
    htmlContent: '<ul><li>Save 10% to 85%<sup>1</sup> on most prescriptions at 60,000 retail pharmacies nationwide.</li><li>Participating retail pharmacies include Walgreens, Target, CVS, and many other independent, national, and regional chains.</li></ul>',
    how_to_use: 'How to use:',
    how_to_use_steps: '<ol><li>Click on the “Check prices” button below.</li><li>Enter the name of your medication.</li><li>Check the ZIP Code and edit if necessary.</li><li>Enter times per day and number of days (if applicable).</li><li>View results by clicking “Find the lowest price” button.</li><li>Compare prices and pharmacy locations and select the best option</li><li>Click “View savings card”.</li><li>At the pharmacy, display the Discount card to the pharmacist to obtain discount.</li></ol>',
    important: '<strong>Important:</strong> If the provider is not familiar with the discount program or you encounter any difficulties, please reach out to our customer support at {{ phone }} immediately. Our dedicated team is ready to assist you in promptly resolving any concerns you may encounter.',
    disclaimer: '<p><sup>1</sup>Actual discount percentages and savings will vary depending on the pharmacy utilized, geographical area and the prescription medication(s) purchased.</p><p><sup>2</sup>NB RX discounts are not insurance.</p>',
    membershipCard: {
      title: 'Rx Valet benefit card',
      subtitle: 'Discount only - NOT INSURANCE! Please present this card at the Pharmacy with a valid prescription',
      information: 'Discounts are available exclusively through participating pharmacies. The range of the discounts will vary depending on the type of prescription and the pharmacy chosen.',
      more_information: 'This program does not make payments directly to pharmacies. Members are required to pay for all prescription purchases. Cannot be used in conjunction with insurance. You may contact customer care anytime with questions or concerns, to cancel your registration or to obtain further information. This program is administered by Medical Security Card Company, LLC, Tucson, AZ.',
    },
    externalLinkTitle: 'Check prices',
  },
  mri: {
    title: 'MRI & CT scans',
    description: 'Save on medical scans and more.',
    descriptionDesktop: 'Save on medical scans and more.',
    save_on: 'Save on medical scans and more',
    information: 'Access high-quality medical imaging, gastroenterology services, and lab tests at discounted pre-negotiated rates.',
    htmlContent: "<ul><li>Save money on MRI, CT scans and more.</li><li>Receive a free diabetes wellness lab test with scheduled imaging services.</li><li>Screen for colorectal cancer with an accurate at-home kit, avoiding the need for a doctor's visit.</li></ul>",
    how_to_use: 'How to use:',
    how_to_use_steps: '<ul><li>RadiologyAssist offers discounted and transparent rates for MRI, CT scans, ultrasounds, X-rays, and mammograms at credentialed radiology centers nationwide.</li><ul><li>Visit <a href="https://radiologyassist.com/"> RadiologyAssist</a> or call (855) 346-5152</li><li>Mention code MEMBERPERKS to receive your discount</li></ul><li>ColonoscopyAssist offers discounted and transparent rates for colonoscopy, upper endoscopy, hemorrhoid banding and gastroenterology consults.<ul><li>Visit <a href="https://colonoscopyassist.com/">ColonoscopyAssist</a> or call (855) 542-6566</li><li>Mention code MEMBERPERKS to receive your discount</li></ul><li>LaboratoryAssist offers 10% off common lab tests at clinical lab locations nationwide.</li><ul><li>Visit <a href="https://laboratoryassist.com/">LaboratoryAssist</a> or call (888) 531-2023</li><li>Mention code MEMBERPERKS to receive your discount</li></ul><li>Colorectal Screening offers 10% off at-home colorectal cancer screening kit.</li><ul><li>Visit <a href="https://coloncancerscreeningkit.com/">ColonCancerScreeningKit</a> to order your kit</li><li>Enter code MEMBERPERKS to receive your discount</li></ul></li></ul>',
    faq: {
      items: [
        {
          title: 'How do I pay?',
          htmlContent: 'You’ll pay when you schedule the appointment. No payment is due at your appointment.',
        },
        {
          title: 'How do I use this benefit?',
          htmlContent: 'Call Monday to Friday, 7:00 am to 7:30 pm Central Time to find a participating facility, request an appointment, or for pricing information. Use the code GALAXY to obtain your discount.',
        },
      ],
    },
  },
  lab: {
    title: 'Lab Testing',
    description: 'Monitor your health with 10% to 80% off typical costs of lab work.',
    descriptionDesktop: 'Know your numbers with direct access to over 1,500 major clinical laboratories nationwide to save you 10% to 80% on typical costs for lab work.',
    save_on: 'National savings on lab work',
    information: 'Know your numbers with direct access to over 1,500 major clinical laboratories nationwide to save you 10% to 80% on typical costs for lab work.',
    htmlContent: '<ul><li>Test categories include allergies, vitamin and cholesterol levels, liver function, fertility, thyroid and more</li><li>Confidential results in as little as 24 hours for most tests</li><li>Order by phone Monday to Friday, 7:00 am to 10:00 pm and Saturday, 8:00 am to 12:00 pm Central Time or online (Provide code R-NEWB when ordering) Lab benefit not available in MD, NJ, NY and RI.</li></ul>',
    externalLinkTitle: 'Order labs',
    faq: {
      items: [
        {
          title: 'What is the testing procedure?',
          htmlContent:
            'First you order your test online, through chat or by telephone (Monday to Friday, 7:00 am to 10:00 pm and Saturday, 8:00 am to 12:00 pm Central Time). If you’re ordering by phone, you will need to provide codeR-NEWB.You then print your requisition form which you must have with you when you go to the patient service center for your blood draw. The lab will not draw your blood without the requisition*. There, your blood is drawn by a certified phlebotomist. The results are received by our office generally within 24-48 hours for most tests and uploaded to your secure online account. *When you go to the lab to have your blood drawn, only take the requisition form and your photo ID with you. If you bring a requisition to a lab OTHER than the one provided through this service, you will receive a bill from the lab for which you will be responsible. Bring NO other requisition forms. If you go to another lab that is not included in the list of participating patient service centers, you be also be responsible for the bill from that lab.',
        },
        {
          title: 'I feel healthy, so why should I get tested?',
          htmlContent:
            'A serious medical condition such as heart disease, prostate cancer or diabetes can exist without noticeable symptoms for up to two years. Early detection is your best defense. A simple blood test can increase your chances of identifying potential medical conditions, and establish a baseline of your normal ranges from which future tests can be monitored.',
        },
        {
          title: 'Will this test be paid for by insurance?',
          htmlContent:
            'This lab testing benefit service does not file insurance claims. Some insurance plans have a wellness or prevention benefit included. The lab testing service can provide you with CPT codes so that you may file for reimbursement yourself. There is no guarantee your claim will be reimbursed. Contact your insurance provider for your benefits and reimbursement options.',
        },
        {
          title: 'Do I need to see my healthcare provider to get tested?',
          htmlContent:
            "Direct access testing allows greater participation in one's own healthcare. Your healthcare provider can refer you to one of the participating lab facilities, but it’s not a requirement. However, you will be asked to provide the specific name of the test you wish to order. This service is not allowed to make any test recommendations. Participating lab facilities CLIA-certified and regulated by appropriate governmental agencies.",
        },
        {
          title: 'What is a patient service center?',
          htmlContent:
            'Patient Service Centers are certified laboratories where patients have blood drawn. These centers are staffed by licensed phlebotomists, and are the same facilities referred to by healthcare providers.',
        },
        {
          title: 'Do I need an appointment?',
          htmlContent:
            'An appointment is usually not required at most Patient Service Centers. You are encouraged to call the lab location to confirm their hours of operation and that an appointment is not required.',
        },
        {
          title: 'Will I be able to understand the results?',
          htmlContent:
            'All test results include the normal reference ranges, with abnormalities indicated. It is recommended you seek a healthcare provider to discuss results outside normal ranges. For assistance in better understanding lab tests and results, please visit labtestsonline.org.',
        },
        {
          title: 'When will I receive the results?',
          htmlContent:
            'Please view test descriptions for expected turnaround time for each test. Most results are available in as little as 24-48 hours after your blood is drawn. You will receive an email when your results have been uploaded to your secure online account.',
        },
        {
          title: 'Will my healthcare provider receive a copy of my results?',
          htmlContent:
            'Your privacy is respected and will remain confidential. You are the only one who receives the results unless you specify otherwise in writing. You can request to have a copy of your results sent to your healthcare provider only with a signed HIPAA release form giving us the authorization to do so.',
        },
      ],
    },
  },
  hearing: {
    title: 'Hearing',
    description: 'Find your ideal hearing solution at retail stores.',
    descriptionDesktop: 'Find an affordable hearing solution, whether at a retail store or through home delivery.',
    save_on: 'Find your ideal hearing solution at retail stores',
    information: 'Know your numbers with direct access to over 1,500 major clinical laboratories nationwide to save you 10% to 80% on typical costs for lab work.',
    faq: {
      items: [
        {
          title: 'How do I find participating providers?',
          htmlContent: "Locate providers near you through the app or online or by calling Monday to Friday, 7:00 am to 7:00 pm Central Time.",
        },
        {
          title: 'How does the program work?',
          htmlContent: 'When you call, an advocate will explain the Amplifon process, request your mailing information and assist you in making an appointment with a hearing care provider. Amplifon will send information to you and the hearing care provider. This will ensure your Amplifon program is activated.',
        },
        {
          title: 'How many times per year can I use my hearing discount?',
          htmlContent: 'There is no limit on the number of times you may use your discount card for any benefit.',
        },
        {
          title: 'Is the discount the same at all participating locations?',
          htmlContent: 'Yes, we have a nationwide network of over 5,100 hearing healthcare providers.',
        },
        {
          title: 'Do you offer more than one brand of hearing aids?',
          htmlContent: 'Yes. We offer more than 2,000 hearing aid models from leading manufacturers such as Oticon, Phonak and Starkey.',
        },
      ],
    },
    benefits: [
      {
        retail: {
          title: 'Retail Hearing Care',
          save_on: 'Find your ideal hearing solution at retail stores',
          information: 'Let Amplifon help you find the right hearing aid solution to fit your lifestyle through personalized service and exceptional products for every budget.',
          htmlContent: '<ul><li>Average savings of 64% off manufacturer suggested retail price for hearing aids</li><li>One year free follow-up care for cleaning and checkups with purchase</li><li>Free two-year supply of disposable batteries or a charging station to keep you powered</li><li>Three-year warranty for loss, repairs, or damage</li><li>100% money-back guarantee during your 60-day risk-free trial</li><li>Offering leading manufacturers including Miracle Ear, Phonak, Starkey, Oticon, ReSound, Signia, Widex, and Unitron</li></ul>',
          faq: {
            items: [
              {
                title: 'How do I find participating providers?',
                htmlContent: "Locate providers near you through the app or online or by calling Monday to Friday, 7:00 am to 7:00 pm Central Time.",
              },
              {
                title: 'How does the program work?',
                htmlContent: 'When you call, an advocate will explain the Amplifon process, request your mailing information and assist you in making an appointment with a hearing care provider. Amplifon will send information to you and the hearing care provider. This will ensure your Amplifon program is activated.',
              },
              {
                title: 'How many times per year can I use my hearing discount?',
                htmlContent: 'There is no limit on the number of times you may use your discount card for any benefit.',
              },
              {
                title: 'Is the discount the same at all participating locations?',
                htmlContent: 'Yes, we have a nationwide network of over 5,100 hearing healthcare providers.',
              },
              {
                title: 'Do you offer more than one brand of hearing aids?',
                htmlContent: 'Yes. We offer more than 2,000 hearing aid models from leading manufacturers such as Oticon, Phonak and Starkey.',
              },
            ],
          },
        },
      },
      {
        home: {
          title: 'Home Delivery Hearing Aids',
          description: 'Smile brighter with big savings at over 262,000* available dental practice locations nationwide',
          save_on: 'Save on hearing solutions with home delivery',
          information: 'Hearing Assist, the #1 direct to consumer hearing aid brand, brings access and affordability to the hearing aid industry with solutions designed to amplify the frequencies most commonly associated with mild to moderate hearing loss, shipped directly to your home.',
          htmlContent: "<ul><li>Discount of $100 off the lowest advertised price per pair of hearing devices at leading retailers or $50 off a single device*</li><li>Save 25% off any hearing accessories**</li><li>Enjoy a flat rate of $99 per year for the Peace of Mind protection plan**</li><li>Includes a 60-day money-back guarantee</li><li>Shipping is free</li><li>No prescription or costly exam needed to purchase your hearing solution <p><span>&#42;</span>Subject to change </p><span>&#42;</span><span>&#42;</span>Discount only applies when a hearing device is purchased</li></ul>",
          externalLinkTitle: 'Purchase hearing aids',
          faq: {
            items: [
              {
                title: 'How does the program work?',
                htmlContent: "Call or visit the website to find the right hearing aid solution for you and pay the discounted price at checkout. Your hearing solutions will be directly delivered to your home with free shipping.",
              },
              {
                title: 'How do I know if the hearing aids will fit me?',
                htmlContent: 'Hearing Assist hearing aids are one size fits most. Some models work with a downloadable app and can be self-tuned to your individual needs. While our hearing aids are designed to work for most, we understand that they won’t work for everyone, so we give you 60 days to try them, risk-free.',
              },
              {
                title: 'Can I use my HSA to purchase hearing aids?',
                htmlContent: 'Yes! Hearing Assist hearing aids are a qualified purchase through Flexible Spending & Health Savings Accounts.',
              },
              {
                title: 'Does the discount only work on the initial purchase?',
                htmlContent: 'No! You can apply the discount to every device purchase, not just the first one.',
              },
              {
                title: 'Who do I call if I need help with my hearing aids?',
                htmlContent: 'US-based customer support can be reached at 800.280.4050 Monday to Saturday, 7:00 am to 10:00 pm Central Time and Sunday, 8:00 am to 5:00 pm Central Time.',
              },
            ],
          },
        },
      },
    ],
  },
  diabetic: {
    title: 'Diabetic supplies',
    description: 'Discounted diabetic supplies mailed to your home.',
    descriptionDesktop: 'Save 10% to 50% on diabetic testing supplies, and get a free fully-audible blood glucose meter with your first order.',
    save_on: 'Discounted diabetic supplies mailed to your home',
    information: 'Save 10% to 50% on diabetic testing supplies, and get a free fully-audible blood glucose meter with your first order. With the convenient online, pre-paid program, you receive discounted diabetic testing supplies shipped directly to your home. Services include:',
    htmlContent: '<ul><li>Free shipping on diabetic supply packages</li><li>Tracking information updates via email or text</li><li>Annual testing program or purchase-as-needed options available</li><li>No prescription required for OTC testing supplies</li><li>100% satisfaction guaranteed, or get your money back To activate your account, enter your Group ID located on your membership card. You will receive an email to confirm your email address and complete registration. Once you have completed activation, you can join a testing program or make a purchase.</li></ul>',
    externalLinkTitle: 'Shop and order',
    faq: {
      items: [
        {
          title: 'Is a prescription required?',
          htmlContent: 'No, a prescription is not required for over-the-counter (OTC) diabetic supplies.',
        },
        {
          title: 'What is the Annual Testing Program?',
          htmlContent: 'Signing up for the Annual Testing Program gives you greater savings on your diabetic supplies with automatic, quarterly shipping. Choose your program based on how many times a day you test. You can also just purchase supplies as needed.',
        },
        {
          title: 'Can I order supplies by phone?',
          htmlContent: 'Yes, call the Rx Valet Customer Care Team Monday to Friday 7:00 am to 7:00 pm or Saturday 8:00 am to 1:00 pm.',
        },
      ],
    },
  },
  travel: {
    title: 'Travel discounts',
    description: 'Enjoy exclusive, deep discounts on travel.',
    descriptionDesktop: 'Enjoy exclusive, deep discounts on travel.',
    save_on: 'Enjoy exclusive, deep discounts on travel',
    information: 'Deeper discounts on hotels, car rentals, flights, and activities all over the world. Most of our travel deals are not available to the public, which means these exclusive rates are much lower than what the average consumer can find online.',
    htmlContent: '<ul><li>Up to 60% off hotel public pricing</li><li>More than 900K hotels worldwide</li><li>200+ airlines, including low cost carriers (e.g., Southwest Airlines)</li><li>Discounted worldwide Disney Parks, Universal Studios, SeaWorld, and other theme park tickets</li><li>16,000+ activities</li><li>No hidden fees or contracts</li></ul>',
    externalLinkTitle: 'Find travel deals',
  },
  general_practice: {
    title: 'In-person Healthcare',
    description: 'Get the medical care you need for less.',
    descriptionDesktop: 'Get the medical care you need for less.',
    save_on: 'Get the medical care you need for less',
    information: 'Save 10% to 40%<sup>1</sup> on most medical services at over 340,000<sup>2</sup> participating physicians, ancillary facilities, and hospitals. Get the care you and your family deserve for less.',
    htmlContent: '<ul><li>You and your immediate family can save using the ValuePoint by MultiPlan® network.</li></ul>',
    how_to_use: 'How to use:',
    how_to_use_steps: '<ol><li>Locate providers near you by selecting the “Find a provider” option below.</li><li>Utilize the map to identify providers in your area.</li><li>Select a provider.</li><li>Call the provider and confirm they accept the <strong>ValuePoint by MultiPlan discount</strong>. It is important to do this <strong>before</strong> arriving at the provider.</li><li>All providers are expected to accept the <strong>ValuePoint by MultiPlan discount</strong>. If you encounter any issues, please contact customer support immediately by calling {{ phone }}.</li><li>Book your appointment.</li><li>When you arrive for your appointment, confirm the price ahead of the consultation and present your digital or physical membership card.</li><li>Should you experience any difficulties during your visit, kindly reach out to our customer support promptly at {{ phone }}.</li></ol>',
    important: '<strong>Important:</strong> If the provider is not familiar with or accepts the <strong>ValuePoint by MultiPlan discount</strong>, please reach out to our customer support at {{ phone }} immediately.  Our dedicated team is ready to assist you in promptly resolving any concerns you may encounter.',
    disclaimer: '<p><sup>1</sup>Actual costs and savings vary by provider, service and geographical area.</p><p><sup>2</sup>As of October 2019.</p>',
    membershipCard: {
      title: 'In person healthcare benefit card',
      subtitle: 'To receive your discount show your card at the reception desk.',
    },
    find_a_doctor: 'Find a doctor',
    providers_found_for_your_location: 'providers found for your location',
    save_your_favorite_providers: 'Save your favorite doctors',
    faq: {
      items: [
        {
          title: 'How many providers are in the network?',
          htmlContent: "The ValuePoint by MultiPlan® network offers access to 275,000 practitioners, 1,300 hospitals, and 67,000 ancillary facilities.",
        },
        {
          title: 'Can I use this benefit in conjunction with health insurance? If so, how does this work?',
          htmlContent: 'Your discount benefit does not work with traditional co-pay insurance, unless the procedure isn’t covered by your insurance. However, you may use the discount benefit to reduce out-of-pocket expenses on non-covered services or indemnity type insurance plans.',
        },
        {
          title: 'Is there a co-pay or deductible?',
          htmlContent: 'No, this is not insurance. You must pay the discounted total at the time of service.',
        },
        {
          title: 'What if I have an emergency?',
          htmlContent: 'In the event of an emergency call 911 or proceed to the nearest emergency department. Present your membership card with the ValuePoint by MultiPlan® logo to administration.',
        },
        {
          title: 'Can my family use this benefit?',
          htmlContent: 'All immediate family, including spouse and legal dependents, can use this benefit.',
        },
        {
          title: 'Can I go to an out-of-network provider and receive a discount?',
          htmlContent: 'No. This benefit only applies to procedures performed by an in-network provider.',
        },
        {
          title: 'What are ancillary medical facilities?',
          htmlContent: 'Ancillary facilities consist of a wide range of specialty healthcare services supporting primary care. Examples include laboratory testing, radiology, genetic testing, diagnostic imaging, rehabilitation centers, hospice care, long-term acute care, nursing facilities, urgent care, as well as physical, occupational, chiropractic, massage and speech therapy.',
        },
        {
          title: 'How do I find out if my provider is in the network?',
          htmlContent: 'Search for participating providers in the app or online, or call Member Support Monday to Friday, 7:00 am to 5:00 pm Central Time for assistance locating a provider.',
        },
        {
          title: 'Can I recommend a provider to join the network?',
          htmlContent: 'Yes, you can submit provider recommendations through the app, online or by calling Member Support. Submit the provider’s information, and MultiPlan will contact the provider about joining the network.',
        },
        {
          title: 'How many times per year can I use this benefit?',
          htmlContent: 'You can use this benefit as often as needed.',
        },
      ],
    },
  },
  doctors_online: {
    title: 'Doctors Online',
    description: 'Email doctors, dentists and other specialists 24/7',
    descriptionDesktop: 'Email doctors, dentists and other specialists 24/7',
    save_on: 'Access a doctor 24/7',
    information: 'The fast, easy way to get trustworthy health information online. Enjoy 24/7 email access to doctors, pharmacists, psychologists, dentists, dieticians and more to get treatment options and advice you understand.',
    htmlContent: '<ul><li>Responses within a few hours</li><li>Unlimited, confidential services include your immediate family</li></ul>',
    message_doctor: 'Message Doctor',
    view_messages: 'View Messages',
    ask_a_doctor: 'Ask a Doctor',
    subject: 'Subject',
    select_specialist: 'Select specialist',
    compose_message: 'Compose message',
    from: 'From:',
    delivered: 'Delivered',
    no_messages: 'You do not currently have any messages',
    no_history: "Ask a question. We’ve got the answers you need.",
    input_placeholder: 'Send message...',
    today: 'Today',
    yesterday: 'Yesterday',
    faq: {
      items: [
        {
          title: 'Am I charged for each new question?',
          htmlContent: 'No. You can use this benefit as often as needed',
        },
        {
          title: 'Are there any services Doctors Online does not provide?',
          htmlContent: 'Emergency issues and prescribing medications are excluded.',
        },
        {
          title: 'Will the medical information I share remain confidential?',
          htmlContent: 'Yes. Health records are kept private and protected.',
        },
        {
          title: 'What services are included?',
          htmlContent: 'You can email board-certified physicians, psychologists, pharmacists, dentists, dietitians, fitness trainers, alternative medicine doctors, and eye doctors for help with treatment options, behavioral health questions, nutrition, fitness and more.',
        },
      ],
    },
  },
  cancellation_policy: {
    title: 'Cancellation policy',
    this_is_not_insurance: 'THIS IS NOT INSURANCE',
    faq: {
      items: [
        {
          title: 'Disclosures:',
          htmlContent: '<ol><li>This plan is not a health insurance policy.</li><li>This plan provides discounts for medical services at certain health care providers.</li><li>This plan does not make payments directly to the providers of medical services.</li><li>The member is obligated to pay for all discounted health care services at the point of service. The discounts may vary based on service, provider, and geographic location.</li></ol>',
        },
        {
          title: 'Cancellation Policy:',
          htmlContent: 'To terminate or cancel the member agreement please call {{ phone }} or send a written cancellation notice to Attn: Member Services at P.O. Box 803475, Dallas, TX 75367-1309. This notice must include your name, member ID number, and be submitted at least three (3) days prior to your next scheduled payment date. This agreement can be canceled for non-payment. If a membership is cancelled for any reason other than non-payment, the member shall receive a pro-rata reimbursement of all periodic charges.',
        },
        {
          title: '30-day money back guarantee:',
          htmlContent: 'If you cancel for any reason within 30 days after the effective date, you will receive a full refund of paid membership fees.',
        },
        {
          title: 'Issue Resolution:',
          htmlContent: 'For any concerns or complaints when using your membership, please call {{ phone }} for assistance. Your issue will be researched, and a resolution reached within 3 business days.',
        },
      ],
    },
  },
  podersalud: {
    how_podersalud_helps_you: 'How PODERsalud helps you',
    get_my_membership: 'Get my membership',
    telehealth : {
      title: 'On-demand Telemedicine',
      description: 'We know you’re busy, so talk to a doctor or medical expert 24/7 by phone or video.',
      items: [
        {
          text: 'Available 24/7',
        },
        {
          text: 'U.S. board-certified doctors',
        },
        {
          text: 'Available in Spanish',
        },
      ],
    },
    prescription: {
      title: 'Prescription Discounts',
      description: 'Just show your PODERsalud card and save up to 80% on your prescriptions at popular pharmacies.',
      items: [
        {
          text: 'Find the best price near you ',
        },
        {
          text: 'Available at 60,000 retail pharmacies nationwide',
        },
      ],
    },
    dental: {
      title: 'Dental Discounts',
      description: 'Save easy at dental appointments. Receive 15% - 50% discounts per visit from participating dentists.',
      items: [
        {
          text: 'Over 260,000 dentists',
        },
        {
          text: 'Cleanings, braces, x-rays, fillings, and more',
        },
      ],
    },
    vision: {
      title: 'Other Medical Discounts',
      description: 'Better vision and diagnosis. Save 10 - 60% on eye health and up to 75% on MRI/CT scans.',
      items: [
        {
          text: 'Schedule with participating provider and present card',
        },
        {
          text: 'Includes neighborhood doctor',
        },
      ],
    },
    earnings: {
      title: 'Exclusive Earnings',
      description: 'Be the first to know. Earn extra through Rewards with exclusive surveys to our PODERsalud members.',
      items: [
        {
          text: 'Access exclusive surveys in app',
        },
        {
          text: 'Exclusive payouts',
        },
      ],
    },
  },
  savings: {
    in_savings: '${{ price }} in savings',
    with_podersalud: 'With <span>PODER</span>salud',
    dental: {
      title: 'Dental',
      description: '<span>Average cost</span> of teeth cleaning for an adult or child',
      per_visit: '<span>${{ price }}</span> / per visit',
    },
    telemedicine: {
      title: 'Telemedicine',
      description: '<span>Average cost</span> for an in person doctors appointment',
      per_visit: '<span>${{ price }}</span> / per visit',
    },
    pharmacy_lisinopril: {
      title: 'Pharmacy',
      description: '<span>Average cost</span> of Lisinopril / (Blood Pressure)',
      per_visit: '<span>${{ price }}</span> / 20mg (30 tablets)',
    },
    pharmacy_amlodipine : {
      title: 'Pharmacy',
      description: '<span>Average cost</span> of Amlodipine (Blood Pressure)',
      per_visit: '<span>${{ price }}</span> / 10 mg (30 tablets)',
    },
    vision : {
      title: 'Vision',
      description: '<span>Average cost</span> for an Eyeglass exam',
      per_visit: '<span>${{ price }}</span> / per visit',
    },
  },
  rx_drug_prices: {
    header: {
      title: 'Rx Valet',
      text: 'Search and compare prices for:',
    },
    brand: "Brand",
    generic: "Generic",
    drug_name: 'DRUG NAME',
    enter_first_three_letters: 'Enter first 3 letters of drug name',
    zipcode: 'ZIP CODE',
    enter_zip_code: 'Enter zip code',
    update_location: 'Update location',
    times_per_day: 'TIMES PER DAY',
    enter_times_per_day: 'Enter times per day',
    number_of_days: 'NUMBER OF DAYS',
    enter_number_of_days: 'Enter number of days',
    find_lowest_price: 'Find the lowest price',
    pharmacies: 'Pharmacies',
    no_results: 'No results available',
    search_results: '{{ result }} providers found near',
    distance: '{{ distance }} Miles',
    view_savings: 'View savings card',
    package_size: 'PACKAGE SIZE',
    select_package_size: 'Select package size',
    drug_card: {
      discount: 'Discount card',
      not_insurance: 'THIS IS NOT INSURANCE.',
      estimated_discount: '*Estimated discount price',
      member_id: 'Member ID:',
      group_id: 'Group ID:',
      bin: 'BIN:',
      pcn: 'PCN:',
      customer_service: 'Customer service:',
      pharmacy_help: 'Pharmacy help desk:',
    },
  },
  revisions: {
    hearing: {
      title: 'Hearing',
      description: 'Find your ideal hearing solution at retail stores',
      descriptionDesktop: 'Find an affordable hearing solution at retail stores.',
      save_on: 'Find your ideal hearing solution at retail stores',
      information: 'Let Amplifon help you find the right hearing aid solution to fit your lifestyle through personalized service and exceptional products for every budget.',
      htmlContent: '<ul><li>Average savings of 64% off manufacturer suggested retail price for hearing aids</li><li>One year free follow-up care for cleaning and checkups with purchase</li><li>Free two-year supply of disposable batteries or a charging station to keep you powered</li><li>Three-year warranty for loss, repairs, or damage</li><li>100% money-back guarantee during your 60-day risk-free trial</li><li>Offering leading manufacturers including Miracle Ear, Phonak, Starkey, Oticon, ReSound, Signia, Widex, and Unitron</li></ul>',
    },
  },
  membership_materials: {
    download: "Download",
    unavailable: "unavailable",
    book: "Membership booklet",
    card: "Card",
  },
  mobile_app_banner: {
    header: "Access all benefits with our App!",
    subheader: "Download now and use your Membership and Group ID to register.",
  },
};

export default benefits;
