const home = {
  simple_greeting: "Hello",
  greeting: "Hello, {{ name }}",
  podercard: {
    digital_banking: "Digital banking for the latino community*",
    sign_up: "Sign up",
  },
  survey_card: {
    minutes: "min",
    you_can_earn: "You can earn",
    time_taken: "Time to complete",
  },
  membership_dashboard: {
    membership_level: "Membership Level",
    start_chat_description: "Start a chat with us now! We're available for you!",
    start_chat_now: "Start Chat Now",
    go_to_rewards: "See more rewards",
    go_to_podercard_app: "Go to PODERcard App",
    premium: "SEP+",
    unsubscribed: "Unsubscribed",
    not_available: "N/A",
    rewards: "My Rewards",
    your_earnings: "Earnings",
    course_completed: "Course Completed",
    podercard: "PODERcard",
    debit_card_holder: "Debit card holder",
    signup_for_podersalud: "Sign up for PODERsalud",
    poder_salud: "PODERsalud",
    my_benefits: "My Benefits",
    share_your_opinion: "Share your opinion, discover helpful information and earn cash rewards!",
    save_with_podersalud: 'Save with PODERsalud medical discounts.',
    dental: "Dental",
    telemedicine: "Rx Valet",
    get_discounts: "Get discounts",
    book_now: "Book now",
    view_all_benefits: "View all benefits",
  },
  index: {
    articles: "Articles",
    view_more: "View more",
    surveys_header_title: "Surveys",
    surveys_header_subtitle: "Earn rewards for sharing your opinion!",
    learn_earn_title: "Learn + Earn",
    learn_earn_spotlight_title: "Learn+Earn Spotlight",
    learn_earn_spotlight_subtitle: "Discover helpful information and get rewarded!",
    unlock_with_premium: "Unlock with PODERsalud",
    discounted_healthcare_title: "Medical Discounts",
    discounted_healthcare_text: "PODERsalud healthcare savings of $2,000+ per year on dental, vision and more!",
    discounted_healthcare_button_text: "Start saving",
    exclusive_resources_title: "Exclusive Resources",
    exclusive_resources_text: "From personal finances, to education to healthcare and immigration. We've got you covered! Coming soon!",
    exclusive_resources_button_text: "Learn more",
    support_title: "Real Time Support",
    support_text: "Access to real-time chat service with our experts. No matter your need, you’ll find trustworthy support.",
    support_button_text: "Sign up today",
    public: {
      main: {
        title: "Welcome to opportunity",
        subtitle: "Trusted advice and services designed for you.",
        cta: "Join the community",
      },
      about: {
        items: [
          {
            title: "Knowledge",
            text: "Answers to your most important questions, from a trusted advisor",
            background: "data:image/svg+xml,%3Csvg width='142' height='23' viewBox='0 0 142 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M4 19C15.2642 11.7948 26.1549 9 38.9889 9C50.4036 9 59.312 13.3636 70.1728 15.5741C89.6777 19.5439 121.047 16.6515 138 4' stroke='url(%23paint0_linear_15862_1014)' stroke-opacity='0.4' stroke-width='8' stroke-linecap='round'/%3E %3Cdefs%3E %3ClinearGradient id='paint0_linear_15862_1014' x1='4' y1='11.5' x2='138' y2='11.5' gradientUnits='userSpaceOnUse'%3E %3Cstop offset='0.0208333' stop-color='%2303C8B0'/%3E %3Cstop offset='0.881281' stop-color='%235C7AFF'/%3E %3C/linearGradient%3E %3C/defs%3E %3C/svg%3E",
            imageURL: "https://dd8u8m75fccea.cloudfront.net/v3/assets/blt224b6cd2a9d5dbf3/bltc28f5cbb985f5de3/6284edebbde8395bd01237de/Knowledge_Icon.png",
          },
          {
            title: "Power",
            text: "Free and discounted benefits that empower you and the community",
            background: "data:image/svg+xml,%3Csvg width='79' height='23' viewBox='0 0 79 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M4 19C9.96833 11.7948 15.7388 9 22.5389 9C28.587 9 33.3071 13.3636 39.0617 15.5741C49.3964 19.5439 66.0174 16.6515 75 4' stroke='url(%23paint0_linear_15862_1018)' stroke-opacity='0.4' stroke-width='8' stroke-linecap='round'/%3E %3Cdefs%3E %3ClinearGradient id='paint0_linear_15862_1018' x1='4' y1='11.5' x2='75' y2='11.5' gradientUnits='userSpaceOnUse'%3E %3Cstop offset='0.0208333' stop-color='%2303C8B0'/%3E %3Cstop offset='0.881281' stop-color='%235C7AFF'/%3E %3C/linearGradient%3E %3C/defs%3E %3C/svg%3E",
            imageURL: "https://dd8u8m75fccea.cloudfront.net/v3/assets/blt224b6cd2a9d5dbf3/blt936f25aa4219362e/6284ee43bde8395bd01237ea/Power.png",
          },
        ],
      },
      description: "Join the community that more than <span>3 million</span> people trust.",
      unlock_now: "Get started",
    },
  },
  categories: {
    finance: "Finance",
    health: "Health",
    education: "Education",
  },
  new: {
    header: {
      title: 'Welcome to',
      title_text: {
        one: 'Opportunity.',
        two: 'Service.',
        three: 'Trust.',
        four: 'a Stronger Future.',
      },
      subtitle: 'Services built for you and your community.',
      sign_up: 'Sign up now',
    },
    services: {
      title: 'Why people join',
      items: [
        {
          icon: 'advisor',
          text: 'Advisor',
          link: '/qa',
        },
        {
          icon: 'medical',
          text: 'Medical Services',
          link: '/podersalud',
        },
        {
          icon: 'rewards',
          text: 'Cash Rewards and Discounts',
          link: '/earn',
        },
        {
          icon: 'bank',
          text: 'Bank Account',
          link: '/podercard',
        },
      ],
    },
    benefits: {
      title: 'Welcome Services and Benefits',
      overview: 'Benefits Overview',
      sign_up: 'Sign up now',
      items: [
        {
          title: 'Advisor and Community Insights',
          subtitle: 'Get help with navigating legal, financial, and other challenges that arise when living in a new country.',
          subItems: [
            {
              icon: 'advisor',
              text: '24/7 Advisor',
            },
            {
              icon: 'advisor',
              text: 'Educational Content',
            },
            {
              icon: 'advisor',
              text: 'Community Workshops',
            },
            {
              icon: 'advisor',
              text: 'Community Polls',
            },
          ],
        },
        {
          title: 'Health and Wellness',
          subtitle: 'Live your healthiest life with access to high quality and affordable health services',
          subItems: [
            {
              icon: 'medical',
              text: 'Dental Care',
            },
            {
              icon: 'medical',
              text: 'Medical Care',
            },
            {
              icon: 'medical',
              text: 'Vision',
            },
            {
              icon: 'medical',
              text: 'Therapists',
            },
          ],
        },
        {
          title: 'Bank Account',
          subtitle: 'Open a bank account quickly and easily, without the need for a social security number or credit history.',
          subItems: [
            {
              icon: 'bank',
              text: 'Pay Bills',
            },
            {
              icon: 'bank',
              text: 'Cash Back Rewards',
            },
            {
              icon: 'bank',
              text: 'Savings',
            },
            {
              icon: 'bank',
              text: 'Build Credit',
            },
          ],
        },
        {
          title: 'Earn Money and Exclusive Rewards',
          subtitle: 'Take advantage of referral bonuses and other incentives for sharing your positive experiences with others.',
          subItems: [
            {
              icon: 'rewards',
              text: 'Job Board',
            },
            {
              icon: 'rewards',
              text: 'Tasks',
            },
            {
              icon: 'rewards',
              text: 'Cash Reward',
            },
            {
              icon: 'rewards',
              text: 'Community Deals',
            },
          ],
        },
      ],
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          title: 'What topics can I expect to learn about?',
          htmlContent: 'We offer a variety of topics to learn about, however our focus are on a couple of key areas that impact everyone - finance and healthcare. Everyone needs to earn enough to support their family, and they need to keep them safe and healthy. We know this can sometimes be challenging in the U.S., that’s why we offer incentivized education around these topics.',
        },
        {
          title: 'Why do we get paid for answering surveys?',
          htmlContent: 'The Latino community continues to grow and our influence grows with it. That’s why brands want to know more about our needs and challenges. Your opinion is incredibly valuable, so you should be paid for it, it’s as simple as that.',
        },
        {
          title: 'How much could I be earning?',
          htmlContent: 'Members can make on average between $0.50 and $5.00 per completed survey. Payment amount depends on the survey length and partner.',
        },
      ],
    },
  },
};

export default home;
