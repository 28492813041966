const home = {
  simple_greeting: "¡Hola",
  greeting: "¡Hola, {{ name }}",
  podercard: {
    digital_banking: "Banco digital para la comunidad*",
    sign_up: "Enviar solicitud",
  },
  survey_card: {
    minutes: "min",
    you_can_earn: "Puede ganar",
    time_taken: "Tiempo necesario",
  },
  membership_dashboard: {
    membership_level: "Membresía",
    start_chat_description: "Comience un chat en vivo con nosotros. ¡Estamos disponibles para ayudarle!",
    start_chat_now: "Abrir chat",
    go_to_rewards: "Ver más recompensas",
    go_to_podercard_app: "Abrir la app de PODERcard",
    premium: "SEP+",
    unsubscribed: "Sin suscripción",
    not_available: "N/A",
    rewards: "Mis Recompensas",
    your_earnings: "Ganancias",
    course_completed: "Cursos terminados",
    podercard: "PODERcard",
    debit_card_holder: "Usuario de tarjeta débito",
    signup_for_podersalud: "Suscribirme a PODERsalud",
    poder_salud: "PODERsalud",
    my_benefits: "Mis Beneficios",
    share_your_opinion: "Gane dinero por compartir su opinión o tomar cursos",
    save_with_podersalud: 'Ahorre con los descuentos médicos de PODERsalud',
    dental: "Dentista",
    telemedicine: "Farmacia",
    get_discounts: "Usar beneficio",
    book_now: "Pedir una cita",
    view_all_benefits: "Ver todos los beneficios",
  },
  index: {
    articles: "Guías",
    view_more: "Ver más",
    surveys_header_title: "Grupo de Opinión",
    surveys_header_subtitle: "¡Gane recompensas por dar su opinión!",
    learn_earn_title: "Aprenda + Gane",
    learn_earn_spotlight_title: "Aprenda + Gane",
    learn_earn_spotlight_subtitle: "¡Descubra información útil al ganar dinero!",
    unlock_with_premium: "Exclusivo con PODERsalud",
    discounted_healthcare_title: "Descuentos médicos",
    discounted_healthcare_text: "Ahorros con PODERsalud de $2,000 anuales en cuidados dentales, de la vista, ¡y más!",
    discounted_healthcare_button_text: "Comience a ahorrar",
    exclusive_resources_title: "Contenido exclusivo",
    exclusive_resources_text: "Guías SEP+ exclusivamente disponibles para suscriptores. ¡Próximamente!",
    exclusive_resources_button_text: "Conozca más",
    support_title: "Asesoría confiable",
    support_text: "¿Tiene preguntas? ¡Tenemos respuestas! Comience un chat en vivo.",
    support_button_text: "Abrir chat",
    public: {
      main: {
        title: "Servicios a la medida",
        subtitle: "Beneficios y consejos exclusivos para toda la comunidad.",
        cta: "Únase a nuestra comunidad",
      },
      about: {
        items: [
          {
            title: "Saber",
            text: "Respuestas a sus preguntas más importantes, por un asesor de confianza.",
            background: "data:image/svg+xml,%3Csvg width='169' height='23' viewBox='0 0 169 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M4 19C17.5338 11.7948 30.619 9 46.0389 9C59.7536 9 70.457 13.3636 83.5062 15.5741C106.941 19.5439 144.631 16.6515 165 4' stroke='url(%23paint0_linear_15862_1022)' stroke-opacity='0.4' stroke-width='8' stroke-linecap='round'/%3E %3Cdefs%3E %3ClinearGradient id='paint0_linear_15862_1022' x1='4' y1='11.5' x2='165' y2='11.5' gradientUnits='userSpaceOnUse'%3E %3Cstop offset='0.0208333' stop-color='%2303C8B0'/%3E %3Cstop offset='0.881281' stop-color='%235C7AFF'/%3E %3C/linearGradient%3E %3C/defs%3E %3C/svg%3E",
            imageURL: "https://dd8u8m75fccea.cloudfront.net/v3/assets/blt224b6cd2a9d5dbf3/bltc28f5cbb985f5de3/6284edebbde8395bd01237de/Knowledge_Icon.png",
          },
          {
            title: "Poder",
            text: "Beneficios gratuitos y de bajo costo que le empoderan a usted y a la comunidad.",
            background: "data:image/svg+xml,%3Csvg width='79' height='23' viewBox='0 0 79 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M4 19C9.96833 11.7948 15.7388 9 22.5389 9C28.587 9 33.3071 13.3636 39.0617 15.5741C49.3964 19.5439 66.0174 16.6515 75 4' stroke='url(%23paint0_linear_15862_1018)' stroke-opacity='0.4' stroke-width='8' stroke-linecap='round'/%3E %3Cdefs%3E %3ClinearGradient id='paint0_linear_15862_1018' x1='4' y1='11.5' x2='75' y2='11.5' gradientUnits='userSpaceOnUse'%3E %3Cstop offset='0.0208333' stop-color='%2303C8B0'/%3E %3Cstop offset='0.881281' stop-color='%235C7AFF'/%3E %3C/linearGradient%3E %3C/defs%3E %3C/svg%3E",
            imageURL: "https://dd8u8m75fccea.cloudfront.net/v3/assets/blt224b6cd2a9d5dbf3/blt936f25aa4219362e/6284ee43bde8395bd01237ea/Power.png",
          },
        ],
      },
      description: "Únase a la comunidad en la que confían más de <span>3 millones</span> de personas.",
      unlock_now: "Únase hoy",
    },
  },
  categories: {
    finance: "Finanzas",
    health: "Salud",
    education: "Educación",
  },
  new: {
    header: {
      title: 'Welcome to',
      title_text: {
        one: 'Opportunity. (SP)',
        two: 'Service. (SP)',
        three: 'Trust. (SP)',
        four: 'a Stronger Future. (SP)',
      },
      subtitle: 'Services built for you and your community. (SP)',
      sign_up: 'Sign up now (SP)',
    },
    services: {
      title: 'Why people join (SP)',
      items: [
        {
          icon: 'advisor',
          text: 'Advisor (SP)',
          link: '/qa',
        },
        {
          icon: 'medical',
          text: 'Medical Services (SP)',
          link: '/podersalud',
        },
        {
          icon: 'rewards',
          text: 'Cash Rewards and Discounts (SP)',
          link: '/earn',
        },
        {
          icon: 'bank',
          text: 'Bank Account (SP)',
          link: '/podercard',
        },
      ],
    },
    benefits: {
      title: 'Welcome Services and Benefits (SP)',
      overview: 'Benefits Overview (SP)',
      sign_up: 'Sign up now (SP)',
      items: [
        {
          title: 'Advisor and Community Insights (SP)',
          subtitle: 'Get help with navigating legal, financial, and other challenges that arise when living in a new country. (SP)',
          subItems: [
            {
              icon: 'advisor',
              text: '24/7 Advisor (SP)',
            },
            {
              icon: 'advisor',
              text: 'Educational Content (SP)',
            },
            {
              icon: 'advisor',
              text: 'Community Workshops (SP)',
            },
            {
              icon: 'advisor',
              text: 'Community Polls (SP)',
            },
          ],
        },
        {
          title: 'Health and Wellness (SP)',
          subtitle: 'Live your healthiest life with access to high quality and affordable health services (SP)',
          subItems: [
            {
              icon: 'medical',
              text: 'Dental Care (SP)',
            },
            {
              icon: 'medical',
              text: 'Medical Care (SP)',
            },
            {
              icon: 'medical',
              text: 'Vision (SP)',
            },
            {
              icon: 'medical',
              text: 'Therapists (SP)',
            },
          ],
        },
        {
          title: 'Bank Account (SP)',
          subtitle: 'Open a bank account quickly and easily, without the need for a social security number or credit history. (SP)',
          subItems: [
            {
              icon: 'bank',
              text: 'Pay Bills (SP)',
            },
            {
              icon: 'bank',
              text: 'Cash Back Rewards (SP)',
            },
            {
              icon: 'bank',
              text: 'Savings (SP)',
            },
            {
              icon: 'bank',
              text: 'Build Credit (SP)',
            },
          ],
        },
        {
          title: 'Earn Money and Exclusive Rewards (SP)',
          subtitle: 'Take advantage of referral bonuses and other incentives for sharing your positive experiences with others. (SP)',
          subItems: [
            {
              icon: 'rewards',
              text: 'Job Board (SP)',
            },
            {
              icon: 'rewards',
              text: 'Tasks (SP)',
            },
            {
              icon: 'rewards',
              text: 'Cash Reward (SP)',
            },
            {
              icon: 'rewards',
              text: 'Community Deals (SP)',
            },
          ],
        },
      ],
    },
    faq: {
      title: 'FAQ (SP)',
      items: [
        {
          title: 'What topics can I expect to learn about? (SP)',
          htmlContent: 'We offer a variety of topics to learn about, however our focus are on a couple of key areas that impact everyone - finance and healthcare. Everyone needs to earn enough to support their family, and they need to keep them safe and healthy. We know this can sometimes be challenging in the U.S., that’s why we offer incentivized education around these topics. (SP)',
        },
        {
          title: 'Why do we get paid for answering surveys? (SP)',
          htmlContent: 'The Latino community continues to grow and our influence grows with it. That’s why brands want to know more about our needs and challenges. Your opinion is incredibly valuable, so you should be paid for it, it’s as simple as that. (SP)',
        },
        {
          title: 'How much could I be earning? (SP)',
          htmlContent: 'Members can make on average between $0.50 and $5.00 per completed survey. Payment amount depends on the survey length and partner. (SP)',
        },
      ],
    },
  },
};

export default home;
